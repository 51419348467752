<template>
  <div class="bg-themed">
    <div class="pt-6 pl-1">
      <space-selection-dropdown  :setSelection="false" :mainViewOptions="mainViewOptions" :contactLeases="contactLeases" @spaceSelectionChanged="mainViewChanged" />
    </div>
    <div class="access-section slide-out-section py-4 mr-3 pl-1" :class="{'pr-6' : showAside && $vuetify.breakpoint.width > 1259}">
      <hb-data-table :headers="headers" :items="billingCycles" :loading="loading">
        <template v-slot:item.unit_number="{ item }">
          <div class="d-flex align-top">
            <hb-unit-icon small :type="getUnitTypeLabel(item)" color="#101318" />
            &nbsp;
            {{ item.unit_number }}
          </div>
      </template>
      <template v-slot:item.status="{ item }">
        <div class="py-3" v-if="item.status">
          <hb-status-general v-if="item.status == 'Upcoming'" icon="mdi-alert-octagon" type="caution">{{ item.status }}</hb-status-general>
          <hb-status-general v-else :status="item.status"></hb-status-general>
        </div>
      </template>
      <template v-slot:item.audit="{ item }">
        <div> {{ item.created_by_name }}</div>
        <div v-if="item.created_at" class="hb-text-light mt-1 mb-n1">{{item.created_at | formatDateTimeCustom('MMM DD, YYYY [@] h:mma')}}</div>
      </template>
      </hb-data-table>
    </div>
  </div>
</template>


<script type="text/babel">

import Loader from '../assets/CircleSpinner.vue';
import SpaceSelectionDropdown from '../settings/spaceSelectionDropdown/spaceSelectionDropdown.vue'; 
import { mapActions } from "vuex";
import {notificationMixin} from "../../mixins/notificationMixin";

export default {
  name: "BillingCyclesList",
  mixins: [ notificationMixin ],
  data() {
      return {
          billingCycles: [],
          headers: [
            { text: 'Space Number', value: 'unit_number' },
            { text: 'Cycle', value: 'billing_cycle' },
            { text: 'Start Date', value: 'start_date' },
            { text: 'End Date', value: 'end_date' },
            { text: 'Status', value: 'status' },
            { text: 'Audit', value: 'audit' },
          ],
          loading: false
      }
  },
  components: {
    Loader,
    SpaceSelectionDropdown
  },
  props: ['contact', 'showAside', 'contactLeases', 'propertyView'],
  created() {
    this.fetchBillingCycles();
  },
  computed: {
    mainViewOptions(){
      var options = [];
      options.push({
        id: null,
        Unit: {
          number: 'Tenant',
          type: 'tenant',
        }
      });
      
      this.contactLeases.forEach(lease => {
        if(this.propertyView === lease?.Unit?.property_id){
          options.push({
            id: lease.id,
            end_date: lease.end_date,
            Unit: {
              number: lease.Unit.number,
              type: lease.Unit.type?.toLowerCase(),
              unit_type_id: lease.Unit.unit_type_id
            }
          });
        }
      })
      return options;
    },
  },
  methods: {
    ...mapActions({
      getBillingCycles: "contactStore/getBillingCycles"
    }),
    async mainViewChanged(filters){
      await this.fetchBillingCycles(filters);
    },
    async fetchBillingCycles(filters = {}) {

      if(!this.contact.id) return;

      let { id: lease_id } = filters;
      this.loading = true;
      this.billingCycles = [];
      try {
        let data = {
          property_id: this.propertyView ? this.propertyView : null,
          contact_id: this.contact?.id
        }

        if(lease_id){
          data.lease_id = lease_id;
        }

        let response = await this.getBillingCycles(data);
        this.billingCycles = response;
      }catch (e) {
        this.showMessageNotification({type: 'error', description: e});
      }finally {
        this.loading = false;
      }
    }
  },
  watch: {
    contact() {
      this.fetchBillingCycles();
    }
  }
}
</script>

<style scoped>
.bg-themed {
  background-color: #F9FAFB;
}
.access-section {
  max-height: 45vh;
  max-height: 45svh;
  height: 45vh;
  height: 45svh;
}
</style>
